import resolveResponse from 'contentful-resolve-response'

type FetchContentfulParams = Record<string, string>

type FetchContentfulOptions = {
  env?: string
  isPreview?: boolean
  resolveLinks?: boolean
}

const prepareParams = (params: FetchContentfulParams) => new URLSearchParams(params)

const fetchContentful = async <T>(
  params: FetchContentfulParams,
  { env, isPreview, resolveLinks = true }: FetchContentfulOptions = {}
): Promise<T> => {
  const envPath = env ? `environments/${env}/` : ''
  const subDomain = isPreview ? 'preview' : 'cdn'
  const spaceId = process.env.CONTENTFUL_SPACE_ID as string
  const token = (isPreview ? process.env.CONTENTFUL_PREVIEW_API : process.env.CONTENTFUL_CONTENT_DELIVERY_API) as string

  if (!spaceId || !token) {
    throw new Error('The space ID and token must be provided.')
  }

  const url = new URL(`https://${subDomain}.contentful.com/spaces/${spaceId}/${envPath}entries`)
  const searchParams = prepareParams(params)
  url.search = searchParams.toString()

  const headers = {
    Authorization: token,
    'Content-Type': 'application/json'
  }

  try {
    const response = await fetch(url, { headers, cache: 'no-store' })
    const parsedResponse = await response.json()

    return resolveLinks ? resolveResponse(parsedResponse) : parsedResponse
  } catch (error) {
    console.error('Fetching data failed:', error)
    throw error
  }
}

export default fetchContentful
