import { CategoryTitle, Container, FlexContainer, LineSeparator, PromotionalCarousel } from '@cheddartv/storybook-news'

import React from 'react'

import { NewslettersList } from '../../data/query/fetchNewslettersList'
import { HomepageData } from '../../data/query/homepage/fetchHomepageData'
import AdUnit from '../Ads/AdUnit'
import MinutelyWidget from '../Ads/MinutelyWidget'
import DvdPreRollAdManager from '../DvdPreRollAdManager'
import Metadata from '../Metadata'
import { useSubdomainContext } from '../SubdomainContext/SubdomainContext'
import ThemeProvider from '../ThemeProvider'
import TopStoriesSection from './TopStoriesSection'

import dynamic from 'next/dynamic'
import Head from 'next/head'
import APElectionWidget from './APWidget'

const WhereToWatchSection = dynamic(() => import('./WhereToWatchSection'))
const PromotedContentSection = dynamic(() => import('../PromotedContent/PromotedContentSection'))
const TalentsSection = dynamic(() => import('../TalentsSection/TalentsSection'))
const NewsletterSection = dynamic(() => import('./NewsletterSection'))
const StoryCollections = dynamic(() => import('./StoryCollections'))
const EventsSection = dynamic(() => import('./EventsSection'))

export const TOP_STORIES_SECTION_ID = 'top-stories-section'

export type HomePageProps = HomepageData & {
  newslettersList: NewslettersList
  regionName: string
}

const HomePage = ({
  calendarId,
  storyListByCategory,
  topStories,
  promotedContent,
  newslettersList,
  whereToWatch,
  talents,
  homepageSponsorship,
  withDvdPreRoll,
  featureApNewsElectionWidget,
  apNewsElectionWidgetLink,
  metadata,
  regionName
}: HomePageProps) => {
  const { path } = useSubdomainContext()

  return (
    <>
      <Head>
        <title>{metadata.title}</title>
        <link rel='canonical' href={`https://${regionName}.news12.com${path || ''}`} />
      </Head>
      <Metadata metadata={metadata} />
      <DvdPreRollAdManager isEnabled={withDvdPreRoll} />
      <ThemeProvider theme='dark'>
        <div id={TOP_STORIES_SECTION_ID}>
          {topStories.length > 0 && (
            <TopStoriesSection showAdSponsorship={homepageSponsorship} topStories={topStories} />
          )}
        </div>
      </ThemeProvider>
      <Container margin={['1.5rem 0 0', '2.5rem auto 0']}>
        <FlexContainer justifyContent='center'>
          <AdUnit adUnitTemplate='leaderboard' id='ad-banner-middle-0' type='leaderboardDynamic' />
        </FlexContainer>
      </Container>
      {/*start AP elections widget TODO: remove after elections*/}
      {featureApNewsElectionWidget && <APElectionWidget href={apNewsElectionWidgetLink} /> }
      {/*end AP elections widget*/}
      <Container>
        <LineSeparator margin={['2.25rem auto', '2.5rem auto']} />
      </Container>
      <PromotedContentSection title={promotedContent.title} items={promotedContent.items} />
      <Container>
        <LineSeparator margin={['2.25rem auto', '2.5rem auto']} />
      </Container>
      <Container padding={{ default: '0 1rem', sm: '0 3.75rem' }}>
        <MinutelyWidget />
      </Container>
      <Container>
        <LineSeparator margin={['2.25rem auto', '2.5rem auto']} />
      </Container>
      <Container margin={['1.5rem 0 0', '2.5rem auto 0']}>
        <FlexContainer justifyContent='center'>
          <AdUnit adUnitTemplate='leaderboard' id='ad-banner-middle-1' type='leaderboardDynamic' />
        </FlexContainer>
      </Container>
      <Container>
        <LineSeparator margin={['2.25rem auto', '2.5rem auto']} />
      </Container>
      <StoryCollections lists={storyListByCategory} />
      <NewsletterSection newslettersList={newslettersList} />
      {whereToWatch ? <WhereToWatchSection title={whereToWatch?.title} platforms={whereToWatch?.platforms} /> : null}
      {talents && (
        <>
          <Container>
            <LineSeparator margin={['1.5rem 0', '2.5rem 0']} />
          </Container>
          <TalentsSection talents={talents} />
        </>
      )}
      {/*This initializes the cityspark events. Cityspark maintains its own Google Tag Manager Account along with its own AmazonPubID which is loaded in after ours and causes an error in the console for apstag.init being called twice.*/}
      {calendarId ? <EventsSection calendarId={calendarId} /> : null}
    </>
  )
}

export default HomePage
