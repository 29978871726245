// todo CHANGE ENV BEFORE MERGING TO MASTER
const fetchQueryWrapper = async (params = {}, transform, isPreview = false, env = null) => {
  const envPath = env ? `environments/${env}/` : ''

  const url = new URL(
    `https://${isPreview ? 'preview' : 'cdn'}.contentful.com/spaces/${
      process.env.CONTENTFUL_SPACE_ID
    }/${envPath}entries`
  )

  Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))

  const res = await fetch(url, {
    headers: {
      Authorization: isPreview ? process.env.CONTENTFUL_PREVIEW_API : process.env.CONTENTFUL_CONTENT_DELIVERY_API
    },
    revalidate: 600
  })
  const data = await res.json()

  return transform(data)
}

export default fetchQueryWrapper
