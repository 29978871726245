import { CategoryTitle, Container, LineSeparator } from '@cheddartv/storybook-news'

import React from 'react'

const APElectionWidget = ({ href }: { href: string }) => {
  return (
    <>
      <Container>
        <LineSeparator margin={['2.25rem auto', '2.5rem auto']} />
      </Container>
      <Container margin={['1.5rem 0 0', '2.5rem auto 0']}>
        <CategoryTitle
          uppercased
          withArrow
          withMarker
          fontWeight='semibold'
          href={href}>
          {'Election 2024 Coverage'}
        </CategoryTitle>
        <iframe
          className='ap-embed'
          title='Live election results via the Associated Press'
          src='https://interactives.ap.org/election-results/customers/layouts/organization-layouts/published/84605/16266.html'
          width='100%'></iframe>
        <script defer src='https://interactives.ap.org/election-results/assets/microsite/resizeClient.js'></script>
      </Container>
    </>
  )
}

export default APElectionWidget
